* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #191d25;
}

#root {
}

input {
    color: #b6e3e3;
}
.sceenic-logo {
    background-image: url('../assets/sceenic.svg');
    background-repeat: no-repeat;
    width: 200px;
    height: 100px;
    background-size: cover;
    background-position: center;
}
.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
}
.session-container {
    width: 94%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.input-content-container{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: stretch;
}

.video-grid {
    display: table-row;
    width:100%;
    max-width:840px;
    margin-top: 20px;
}

.participant {
    display: flex;
    margin-top: 8px;
}

video {
    width: 100%;
    max-width:840px;
    border-radius: 5px;
    min-height: 40vh;
    max-height: 70vh;
    object-fit: cover;
}

.user-name {
    position: absolute;
    color: #1d2b2b;
    font-weight: bolder;
    background-color: transparent;
    padding:5px;
    border-radius: 2px;
    font-family: monospace;
    font-size: 18px;
    -webkit-text-stroke: 1px #fff; /* width and color */
}

.buttons {
    display: flex;
    justify-content: center;
}
.buttons-dis {
    display: flex;
    justify-content: flex-end;
}
.disconnect-button {
    margin-top: 7px;
    background-image: url("../assets/leave-red.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    border: 2px solid #7d0202;
    cursor: pointer;
}

.connect-button {
    border-radius: 10px;
    width:100%;
    font-size: 18px;
    height: 60px;
    margin-top: 20px;
    color: teal;
    border: 2px solid teal;
    cursor: pointer;
}
.quality-dialog {
    position: absolute;
    top: 0;
    background-color: #103434;
    padding: 10px;
}

.scanner-container {
    width: 90%;
    max-width: 750px;
}
.scanner-container section div {
    display: none;
}
.scanner-container video {
    z-index: 2;
}
.qr-container {
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qr-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.config-button {
    width: 25px;
    height: 25px;
    padding:0;
    border: 1px solid teal;
    color: teal;
    cursor: pointer;
    border-radius: 15px;

}

@media (orientation: portrait) {
    .session-container {
        flex-direction:column
    }
    .video-controls{
        display: flex;
        flex-direction: row;
    }
    .control-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 5px;
    }
    .empty-logo {
        background-image: url('../assets/sceenic.svg');
        background-repeat: no-repeat;
        width: 200px;
        height: 100px;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 1px;
    }
}
@media (orientation: landscape){
    .session-container {
        flex-direction: row;
    }
    .control-container {
        display: flex;
        flex-direction: row;
        width: 80%;
        height: 100%;
        align-items: flex-end;
        padding: 0 5px;
    }
    .video-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:center;
        z-index: 1;
    }
    .input-content-container {
        z-index: 1;
    }
    .sceenic-logo {
        position: absolute;
        right: 10vw;
        z-index: 0;
    }
    .empty-video-grid {
        display: none;
    }
    .empty-logo {
        background-image: url('../assets/sceenic.svg');
        background-repeat: no-repeat;
        width: 200px;
        height: 100px;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 1px;
    }
}