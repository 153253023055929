.customButton {
    padding: 5px 15px;
    color: teal;
    font-size: 14px;
    background: transparent;
    border: 1px solid teal;
    cursor: pointer;
    margin-inline-end: 5px;
    margin-top: 5px;
}

.customButton:last-child {
    margin-right: 0;
}

.enableVButton {
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid teal;
    background-color: rgba(0, 128, 128, 0.51);
    cursor: pointer;
    margin-inline-end: 5px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-top: 5px;
    background-image: url("../../../assets/camera-white.svg");
}

.disableVButton {
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #A7A8A882;
    background-color: rgba(167, 168, 168, 0.51);
    cursor: pointer;
    margin-inline-end: 5px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-top: 5px;
    background-image: url("../../../assets/camera-stopped.svg");
}

.enableAButton {
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid teal;
    background-color: rgba(0, 128, 128, 0.51);
    cursor: pointer;
    margin-inline-end: 5px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-top: 5px;
    background-image: url("../../../assets/microphone-white.svg");
}

.disableAButton {
    /*padding: 5px 15px;*/
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #A7A8A882;
    background-color: rgba(167, 168, 168, 0.51);
    cursor: pointer;
    margin-inline-end: 5px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-top: 5px;
    background-image: url("../../../assets/microphone-muted.svg");
}
